<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">A must-have E-ticket package for sightseeing in Okayama. Select popular facilities and restaurants in Okayama area. You can choose 3 of your favorite facilities within a week. Present the QR code on the E-ticket to easily enter the venue.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">How to use</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● The same facility can only be used once, and the same facility can not be re-entered.<br>
          ● Ticket Validity Period: <span>90 Days</span> After Purchase.<br>
          EX: Tickets Purchased On <span>7/1</span> ⇒ <span>Ticket Validity Period:7/1~9/28.</span><br>
          ● Ticket Validity Period: After Scanning The Code At The First Facility, It Is Valid For <span>7 Days.</span><br>
          EX: If You Start To Use It On 7/1 ⇒ Validity Period:<span> 7/1~7/7.</span><br>
          ● Available Facilities: You Can Use <span>3</span>  Facilities During The Valid Period.<br>
          ● Please Note That When You Purchase More Than One Ticket In A Reservation, The Starting Date Will Apply To All Tickets. <br>
          (<span>The Validity Period Is 7 Days</span>).<br>
          ● When The Ticket Has Been Used, The <span>7 Day</span> Validity Period Will Not Exceed The <span>90 Day</span> Validity Period Of The Ticket Itself.<br>
          EX: Ticket Period: <span>7/1~9/28</span>, If The Ticket Starts On <span>9/27</span>, The Valid Time Is: <span>9/27~9/28</span>.<br>
          ● Facilities That Are Unused Cannot Be Refunded.<br>
          ● Please Check The Information About Each Facility, Business Hours, And Public Holidays In Advance At The Following Links.
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='Notice'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='Redemption Location' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='Official Website' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- notice特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfokayama/en/havefun_title-en.png'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 10,
          img: require('@/assets/images/hfokayama/10_EN.png'),
          title: ["Okayama Castle Main Tower Admission Ticket + 1st Floor 'UJO Cafe' Seasonal Sundae"],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'Address: 2-3-1 Marunouchi, Kita-ku, Okayama City, 700-0823',
                'Admission Ticket:',
                'Please present your electronic ticket to the staff at the Okayama Castle admission counter, where they will provide you with a physical admission ticket.',
                '"UJO Cafe" Seasonal Sundae:',
                'Present both your electronic ticket and the physical admission ticket at the 1st floor of the "UJO Cafe," and the staff will exchange them for your "Seasonal Sundae".',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['Okayama Castle:'],noColor:true },
                { cid: 2, text: ['https://okayama-castle.jp/home-zh/'] },
                { cid: 3, text: ['UJO Cafe:'],noColor:true },
                { cid: 4, text: ['https://okayama-castle.jp/gather-shop-zh/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for business hours in advance.','<br>※Closed days: 12/29～12/31'] },
                { cid: 2, text: ['Please understand that there may be situations where the coupon cannot be used due to the restaurant being at full capacity.'] },
                { cid: 3, text: ['The picture is for reference only; please focus on the actual physical product.'] },
                ]
            }
          ]
        },
        {
          id: 1,
          img: require('@/assets/images/hfokayama/01_EN.png'),
          title: ['Admission ticket for Yumeji Art Museum (Main Building)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒703-8256 2-1-32 Hama, Naka-ku, Okayama-shi, Okayama-ken'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://yumeji-art-museum.com/english/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for business hours in advance.'] },
                { cid: 2, text: ['Only the main building is accessible. (Entry of the Youth Mountain villa requires an additional fee.)'] },
              
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hfokayama/02_EN.png'),
          title: ['Hotel Granvia Okayama 「lumiere」 1000 Yen Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒700-8515,1-5 Ekimotomachi, Kita-ku, Okayama City'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.granvia-oka.co.jp/en/restaurant/#lum'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen coupon.'] },
                { cid: 2, text: ['Restaurant serving hours: 9:00 to 21:00 (last order at 20:30). Notice, the parfait is served from 12:00 to 21:00 (last order at 20:30).'] },
                { cid: 3, text: ['The fruit parfait contains different fruits depending on the season. Product photos are for reference only.'] },
                { cid: 4, text: ['Any amount exceeding the discount coupon value will be borne by the customer.'] },
                { cid: 5, text: ['Orders cannot be canceled after payment.'] },
                { cid: 6, text: ['Please understand that there may be situations where the coupon cannot be used due to the restaurant being at full capacity.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 3,
          img: require('@/assets/images/hfokayama/03_EN.png'),
          title: ['Okayama Okaden Museum Admission Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒703-8281 Higashiyama 2 Chome-3-33, Naka, Okayama City, Okayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://okayama-kido.co.jp/okadenmuseum/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for business hours in advance.'] },
              
                ]
            }
          ]
        },
        // 
        {
          id: 4,
          img: require('@/assets/images/hfokayama/04_EN.png'),
          title: ['café Antena 1,000 Yen coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒700-0812 1 Chome-8-23 Izushicho, Kita Ward, Okayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://okayama-parfait.com/parfait/264.html'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is any price difference, it must be paid on-site.'] },
                { cid: 4, text: ['Please note that there is a possibility that it may not be available due to being fully booked.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 5,
          img: require('@/assets/images/hfokayama/05_EN.png'),
          title: ['Okayama Prefecture Kurashiki Bikan Historical Quarter Rambler Coupons'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'KURASHIKI EKIMAE TOURIST INFORMATION OFFICE(倉敷駅前観光案內所)','1-7-2 Achi, Kurashiki, Okayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://okayama.visit-town.com/visittour/cp_kurashiki-machi-2023/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['There are no substitute products when there is out of stock. please use it in other shops. Additionally, the store may be temporarily closed.'] },
                { cid: 2, text: ['This ticket cannot be reused or used in conjunction with other discounts.'] },
                { cid: 3, text: ['NO refund will be accepted for the unused tickets.'] },
                { cid: 4, text: ['Please keep the tickets carefully, since travelers who are lost their tickets will not be reissued.'] },
                { cid: 5, text: ['The validity period of this coupon is 2023/4/1 - 2024/3/31'] },
                { cid: 6, text: ['Stores may be added or removed without notice, and product content may be changed.'] },
                { cid: 7, text: ['1 set (10 sheets) for 1 person.'] },
                { cid: 8, text: ['The number of tickets required for each store varies. There are also facilities that require 10 tickets upon entry or experience. If you would like to use other facilities, please purchase two or more sets.'] },
                { cid: 9, text: ['Please check the guide before visiting the facility.'] },
  
              ]
            }
          ]
        },
        // 
        {
          id: 6,
          img: require('@/assets/images/hfokayama/06_EN.png'),
          title: ['Betty Smith Factory Outlet 1,500 Yen coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒711-0906 BettySmith, 5-chome 2−70, Shimono Town, Kojima, Kurashiki City, Okayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://betty.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1500 yen discount coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hfokayama/07_EN.jpg'),
          title: ['City of Denim Kojima Tour Bus 1 Day Pass '],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒711-0913 Kojimaajino, Kurashiki, Okayama 3-2-10'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['http://shimoden.net/rosen/kikaku/jeans.html#oneday'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Once the One-Day Pass is exchanged, it cannot be refunded. Even if it is lost or stolen, it cannot be reissued.'] },
                { cid: 2, text: ['Refunds are not available in case of bus delays or closures of tourist facilities due to natural disasters, earthquakes, or other reasons.'] },
                { cid: 3, text: ['If you miss the bus and have to take a taxi or alternative transportation, no refunds will be provided.'] },
                { cid: 4, text: ['The applicable routes include the Jeans Bus, "FUREAI," "TOKOHAI," and the "Oujidake Line" (to Oujidake Trailhead Station).'] },
                { cid: 5, text: ['You must exchange the One-Day Pass at the Kojima branch in advance. ※Exchanges cannot be made on the bus.'] },

                ]
            }
          ]
        },
        // 
        {
          id: 8,
          img: require('@/assets/images/hfokayama/08_EN.jpg'),
          title: ['WASHU BLUE RESORT  Kasago hot spring one day pass'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒711-0926  303-17 Shimotsuifukiage, Kurashiki, Okayama '],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['http://www.kasago.jp.e.aah.hp.transer.com/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website in advance for business hours.'] },
              
                ]
            }
          ]
        },
        // 
        {
          id: 9,
          img: require('@/assets/images/hfokayama/09_EN.png'),
          title: ['JR Okayama Station-mae Electronics Retail Store "Bic Camera Okayama Station-mae Store" 1,000-yuan Voucher'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒700-0023 General Information Desk on 1F, Ekimae-cho 1-1-1, Kita-ku, Okayama City, Okayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.biccamera.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['A ticket cannot be exchanged for cash.'] },
                { cid: 2, text: ['The company is not responsible for any theft, loss or damage.'] },
                { cid: 3, text: ['It cannot be exchanged for other product coupons, postcards, stamps, cigarettes, and other products designated by the company.'] },
                { cid: 4, text: ['Please check the business hours of each store in advance on the related official website.'] },
                { cid: 5, text: ['If there is a price difference of the purchase, the difference needs to be paid on-site at the very moment.'] },
                ]
            }
          ]
        },
        // 
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkayama&l=en')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okayama-1-week-free-pass/en', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>